<template>
  <Modal
    stop-mobile-max-height
    style-back-transparent
    history-mode
    :hide-back-controls="true"
    @close="$emit(EVENTS.close)"
  >
    <div class="history-panel">
      <div
        v-for="(post, ndx) in items"
        :key="ndx"
        :class="{ active: ndx === activeIndex }"
        class="history-panel__item"
      >
        <div
          v-if="ndx < activeIndex"
          :class="{ finished: ndx < activeIndex }"
          class="item-scale"
        />
        <div
          v-if="ndx === activeIndex"
          class="item-scale"
          :class="{
            scaleActiveTutorial: ndx === activeIndex,
            paused: pauseTimeSeconds >= 1,
          }"
        />
      </div>
    </div>
    <div
      class="history-page"
      @click="clickPage"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @touchstart="onTouchDown"
      @touchend="onTouchUp"
    >
      <div class="history-page-wrap-image">
        <div :class="`history-page-wrap-image-item slide${activeIndex}`" />
        <!-- hide for loading -->
        <div :class="`history-page-wrap-image-item slide0 hideimg`" />
        <div :class="`history-page-wrap-image-item slide1 hideimg`" />
        <div :class="`history-page-wrap-image-item slide2 hideimg`" />
      </div>
      <div class="history-back" @click.stop="$emit(EVENTS.close)">BACK</div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import { onMounted, ref } from 'vue';
import '@/assets/icons/arrowHistory';
import '@/assets/icons/arrow_down';
import { useModals } from '@/modules/modals/api';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal },
  props: {},
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const { showModal, modalsByName, hideModal } = useModals();
    const items = ref([{}, {}, {}]);
    const activeIndex = ref(0);
    const inProcessing = ref(false);
    const needUp = ref(false);

    const pauseTimeSeconds = ref(0);
    const pauseInterval = ref(null);

    const onTouchDown = () => {
      if (needUp.value) {
        return;
      }
      needUp.value = true;
      if (inProcessing.value) {
        return;
      }

      pauseTimeSeconds.value = 1;

      pauseInterval.value = setInterval(() => {
        pauseTimeSeconds.value += 1;
      }, 500);
    };

    const onMouseDown = () => {
      if (window.document.body.clientWidth <= 1024) {
        return;
      }

      if (needUp.value) {
        return;
      }
      needUp.value = true;
      if (inProcessing.value) {
        return;
      }

      pauseTimeSeconds.value = 1;

      pauseInterval.value = setInterval(() => {
        pauseTimeSeconds.value += 1;
      }, 500);
    };

    const onTouchUp = () => {
      needUp.value = false;

      setTimeout(() => {
        pauseTimeSeconds.value = 0;
        clearInterval(pauseInterval.value);
      }, 200); // sleep for CLICK event and check 0 pause time
    };

    const onMouseUp = () => {
      if (window.document.body.clientWidth <= 1024) {
        return;
      }

      needUp.value = false;

      setTimeout(() => {
        pauseTimeSeconds.value = 0;
        clearInterval(pauseInterval.value);
      }, 200); // sleep for CLICK event and check 0 pause time
    };

    const goNextSlide = () => {
      if (activeIndex.value === items.value.length - 1) {
        activeIndex.value = 0;
        hideModal(modalsByName.openAnyTrade);
      } else {
        activeIndex.value++;
      }
    };

    const onFinishedAnimate = () => {
      goNextSlide();

      setTimeout(() => {
        subscribeOnFinishAnimate();
      }, 1000);
    };

    const subscribeOnFinishAnimate = () => {
      if (activeIndex.value === -1) {
        goNextSlide();
      }
      setTimeout(() => {
        document
          .querySelector('.scaleActiveTutorial')
          .addEventListener('animationend', onFinishedAnimate, false);
      }, 500);
    };

    onMounted(() => {
      subscribeOnFinishAnimate();
    });

    const clickPage = (e) => {
      if (inProcessing.value) {
        return;
      }
      inProcessing.value = true;
      setTimeout(() => {
        inProcessing.value = false;
      }, 700);
      if (pauseTimeSeconds.value >= 2) {
        // if pause time, that it is not click
        return;
      }

      const isForward = e.clientX > document.body.clientWidth / 2;

      if (isForward) {
        if (activeIndex.value === items.value.length - 1) {
          activeIndex.value = 0;
          hideModal(modalsByName.tutorialHistory);
        } else {
          activeIndex.value++;
        }
      } else {
        if (activeIndex.value === 0) {
          return;
        }
        activeIndex.value--;
      }
      subscribeOnFinishAnimate();
    };

    const login = () => {
      document.body.style.overflow = '';
      emit(EVENTS.close);
      showModal(modalsByName.login);
    };

    const onClose = () => {
      document.body.style.overflow = '';
      emit(EVENTS.close);
    };

    onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

    return {
      EVENTS,
      items,
      activeIndex,
      pauseTimeSeconds,
      onMouseDown,
      onMouseUp,
      onTouchDown,
      onTouchUp,
      clickPage,
      onClose,
      login,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

@keyframes animateScale {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.history-page {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .history-back {
    position: absolute;
    bottom: 0;
    left: 0;
    user-select: none;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.9);
    font-size: 17px;
    font-family: 'Gotham_Pro_Bold';
    color: #fff !important;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .btn-login {
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #fff;
    color: #fff;
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 16px;
    margin: 25px auto;
    width: 90%;
    font-family: 'Gotham_Pro_Bold';
  }

  .description-title {
    user-select: none;
    font-size: 20px;
    font-family: 'Gotham_Pro';
    text-align: center;
    color: #fff;
    line-height: 25px;
    margin-bottom: 25px;

    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  .description {
    user-select: none;
    margin-bottom: 50px;
    font-size: 13px;
    font-family: 'Gotham_Pro_Regular';
    text-align: center;
    color: #fff;
    line-height: 15px;
    opacity: 0.7;
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #000;
    opacity: 0.7;

    &.filled {
      opacity: 0.9;
    }

    @media (min-width: 1024px) {
      width: 50%;
      left: initial;
      right: 0;
      bottom: 20px;
      opacity: 0;

      .btn-login {
        width: 70%;
        margin: 0 25px 25px 25px;
      }

      .description-title {
        text-align: left;
        margin-left: 25px;
      }
    }
  }
}

.history-page-wrap-image {
  width: 100%;
  max-width: 540px;
  min-width: 270px;
  max-height: 100vh;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    max-width: 955px;
    min-width: 667px;
  }
}

.enter {
  user-select: none;
  position: absolute;
  bottom: 10px;
  background-image: url('~@/assets/tutorial/enter.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  width: 64px;
  height: 33px;
  bottom: 22px;
  left: calc(50% - 32px);

  @media (min-width: 1024px) {
    display: none;
  }
}

.history-page-wrap-image-item {
  user-select: none;
  padding-bottom: 216.6666%;
  position: relative;
  background-image: url('~@/assets/open_any_trade/mob1.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0s ease-out;

  @media (max-width: 480px) {
    padding-bottom: 216.6666%;
    height: auto;
  }

  @media (min-width: 1024px) {
    background-image: url('~@/assets/open_any_trade/web1.svg');
  }

  &.slide0 {
    background-image: url('~@/assets/open_any_trade/mob1.svg');
    opacity: 1;

    @media (min-width: 1024px) {
      background-image: url('~@/assets/open_any_trade/web1.svg');
    }
  }

  &.slide1 {
    background-image: url('~@/assets/open_any_trade/mob2.svg');
    opacity: 1;

    @media (min-width: 1024px) {
      background-image: url('~@/assets/open_any_trade/web2.svg');
    }
  }
  &.slide2 {
    background-image: url('~@/assets/open_any_trade/mob3.svg');
    opacity: 1;

    @media (min-width: 1024px) {
      background-image: url('~@/assets/open_any_trade/web3.svg');
    }
  }

  &.hideimg {
    z-index: -1;
    visibility: hidden;
  }
}

.history-panel {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 10px;
  height: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    padding: 0 20px;
  }

  &__item {
    margin: 0 3px;
    width: 100%;
    height: 3px;
    border-radius: 6px;
    background: rgb(77, 77, 77);

    position: relative;

    .item-scale {
      position: absolute;
      background: #fff;
      left: 0;
      top: 0;
      height: 3px;
      width: 0%;
      border-radius: 6px;

      &.scaleActiveTutorial {
        -webkit-animation: animateScale 5s ease-in;
        -webkit-animation-fill-mode: forwards;

        &.paused {
          -webkit-animation-play-state: paused;
          -moz-animation-play-state: paused;
          -o-animation-play-state: paused;
          animation-play-state: paused;
        }
      }

      &.finished {
        width: 100%;
      }
    }

    &.active {
      opacity: 1;
    }

    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 10px;
    }
  }
}
</style>
