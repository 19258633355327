import { connMarkets } from '@/helpers/signalR';
import { logMessage } from './helpers';
export class Requester {
  constructor(headers) {
    if (headers) {
      this._headers = headers;
    }
  }
  sendRequest(datafeedUrl, urlPath, params) {
    // jfyi: code from library;
    // if (params !== undefined) {
    //   const paramKeys = Object.keys(params);
    //   if (paramKeys.length !== 0) {
    //     urlPath += '?';
    //   }
    //   urlPath += paramKeys
    //     .map((key) => {
    //       return `${encodeURIComponent(key)}=${encodeURIComponent(
    //         params[key].toString()
    //       )}`;
    //     })
    //     .join('&');
    // }
    // logMessage('New request: ' + urlPath);
    // // Send user cookies if the URL is on the same origin as the calling script.
    // const options = { credentials: 'same-origin' };
    // if (this._headers !== undefined) {
    //   options.headers = this._headers;
    // }
    // eslint-disable-next-line no-restricted-globals
    const method = `udf/${urlPath}`;
    const opts = params ? [method, params] : [method];

    logMessage('New request: %j', opts);

    return connMarkets.invoke.apply(connMarkets, opts);
  }
}

/**
 *from 1723742084,"to":1723742684
      [1723742580,     1723742640 "nextTime":1723742084}
 */
