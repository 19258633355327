<template>
  <ItemLabelValue
    v-if="!(isCanceled || isProposed)"
    :label="$t('order.card.titles.profitLoss')"
    :is-red="position.pl < 0"
    :is-green="position.pl > 0"
    :value="`${
      (position.pl > 0 ? '+' : '') +
      getFormatedAmount(position.pl, position.base_currency === 'USD') +
      ' ' +
      position.base_currency
    }`"
  />

  <ItemLabelValue
    v-if="!(isCanceled || isProposed)"
    :label="$t('order.card.titles.profitLossPercent')"
    :is-red="position.pl_percent < 0"
    :is-green="position.pl_percent > 0"
    :value="`${
      (position.pl_percent > 0 ? '+' : '') +
      +(position.pl_percent && position.pl_percent.toFixed(2))
    }%`"
  />

  <ItemLabelValue
    :label="$t('order.card.titles.side')"
    :is-red="position.side === PositionSides.SELL"
    :is-green="position.side === PositionSides.BUY"
    :value="`${position.side.toUpperCase()}`"
  />

  <ItemLabelValue
    :label="$t('order.card.titles.entryPrice')"
    :value="`${
      position.side === PositionSides.BUY
        ? PositionSideTypes.ask
        : PositionSideTypes.bid
    }  ${calcEntryPrice(position)}`"
  />

  <ItemLabelValue
    v-if="!(isOpened || isProposed || isCanceled)"
    :label="$t('order.card.titles.closePrice')"
    :value="`${
      position.side === PositionSides.BUY
        ? PositionSideTypes.bid
        : PositionSideTypes.ask
    }  ${calcClosePrice(position)}`"
  />

  <ItemLabelValue
    :label="$t('order.card.titles.amount')"
    :value="`${
      position.base_currency === 'USD'
        ? getFormatedAmount(position.amount, true)
        : toCurrencyFormat(position.amount)
    } ${position.base_currency === 'USD' ? 'USD' : 'BSV'}`"
  />

  <ItemLabelValue
    :label="
      $t('order.card.titles.margin') +
      `${position.is_bounty ? ' (bounty)' : ''}`
    "
    :value="`${
      fiorinMode && !fiorinStables.includes(position?.currency?.toLowerCase())
        ? toUSDMarginFormat(position.margin, 2)
        : toCurrencyFormat(position.margin)
    } ${
      !activeConnect?.provider
        ? 'USD'
        : fiorinMode
        ? position.is_bounty
          ? 'USD'
          : position?.currency
        : 'BSV'
    }`"
    :hint-options="{ top: '-40px', left: '70px' }"
    :hint-title="$t('order.card.hints.margin')"
  />

  <ItemLabelValue
    :label="$t('order.card.titles.leverage')"
    :value="`${(+toCurrencyFormat(position.leverage))
      .toFixed(1)
      .replace('.0', '')}×`"
    :hint-options="{ top: '-40px', left: '80px' }"
    :hint-title="$t('order.card.hints.leverage')"
  />

  <ItemLabelValue
    v-if="position.take_profit_price"
    :label="$t('order.card.titles.takeProfit')"
  >
    <div>
      {{
        `${
          position.side === PositionSides.BUY
            ? PositionSideTypes.bid
            : PositionSideTypes.ask
        } ${toCurrencyFormat(
          position.take_profit_price,
          null,
          floatList[position.market]
        )}`
      }}
    </div>
  </ItemLabelValue>

  <ItemLabelValue
    v-if="position.stop_loss_price"
    :label="$t('order.card.titles.stopLoss')"
  >
    <div>
      {{
        `${
          position.side === PositionSides.BUY
            ? PositionSideTypes.bid
            : PositionSideTypes.ask
        } ${toCurrencyFormat(
          position.stop_loss_price,
          null,
          floatList[position.market]
        )}`
      }}
    </div>
  </ItemLabelValue>
  <ItemLabelValue
    v-if="!(isProposed || isCanceled)"
    :label="$t('order.card.titles.holdingFee')"
    :value="`${toCurrencyFormat(position.decay)} ${
      position.base_currency === 'USD' ? 'USD' : 'BSV'
    }`"
    :hint-options="{ top: '-25px', left: '100px' }"
    :hint-title="$t('order.card.hints.holdingFee')"
  />
  <ItemLabelValue
    v-if="!isProposed"
    :label="$t('order.card.titles.holdingFeeRate')"
    :value="`${
      (position.decay_rate && toCurrencyFormat(position.decay_rate * 100)) ||
      '0'
    }% ${position.is_bounty ? 'x4 if bounty' : ''}`"
    :hint-options="{ top: '-25px', left: '140px' }"
    :hint-title="$t('order.card.hints.holdingFeeRate')"
  />
  <ItemLabelValue
    v-if="+positionLoyaltyFeeValue"
    :label="$t('blocks.titles.loyaltyFee')"
    :hint-options="{ top: '-40px', left: '100px' }"
    :hint-title="$t('blocks.titles.loyaltyFeeHint')"
    :value="`${toCurrencyFormat(positionLoyaltyFeeValue)} USD`"
  />
  <ItemLabelValue
    v-if="position.isProfitVoided"
    :label="$t('blocks.titles.scalpingFee')"
    :hint-options="{ top: '-40px', left: '100px' }"
    :value="`${toCurrencyFormat(position.scalpingFee)} USD`"
  />
  <ItemLabelValue
    v-if="!(isCanceled || isProposed)"
    :label="$t('order.card.titles.netProfitLossBsv')"
    :is-red="(position.netBalance || 0) < 0"
    :is-green="(position.netBalance || 0) >= 0"
    :value="
      formatNetProfitLoss(position.netBalance || 0, position.base_currency)
    "
  />
  <ItemLabelValue
    v-if="!(isOpened || isProposed)"
    :label="$t('order.card.titles.closeReason')"
    :value="closeReason"
  />
  <ItemLabelValue
    v-if="!isCanceled"
    :label="$t('order.card.titles.liquidationPrice')"
    :value="`${
      position.side === PositionSides.BUY
        ? PositionSideTypes.bid
        : PositionSideTypes.ask
    } ${toCurrencyFormat(
      position.liquidation_price,
      null,
      floatList[position.market]
    )}`"
    :hint-options="{ top: '-40px', left: '140px' }"
    :hint-title="$t('order.card.hints.liquidationPrice')"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.created')"
    :value="showDateFormat(position.proposed)"
  />
  <ItemLabelValue
    v-if="!!position.opened"
    :label="$t('order.card.titles.opened')"
    :value="showDateFormat(position.opened)"
  />
  <ItemLabelValue
    :label="closedDynamicTitleLabel"
    :value="showDateFormat(position.closed)"
  />
  <ItemLabelValue
    v-if="position.rebate"
    :label="$t('order.card.titles.liquidityRebate')"
    :value="`-${toCurrencyFormat(position.rebate)}`"
    :hint-options="{ top: '-100px', left: '135px' }"
    :hint-title="$t('order.card.hints.liquidityRebate')"
  />
</template>

<script>
import {
  toCurrencyFormat,
  getFormatedAmount,
  toUSDMarginFormat,
} from '@/helpers/utils';
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import usePositionCalcs from '@/compositions/usePositionCalcs';
import '@/assets/icons/pen';
import DateTime from 'luxon/src/datetime.js';
import { PositionSides } from '@/config/constants';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PositionSideTypes } from '@/modules/positions/helpers';
import { usePosition } from '@/compositions/usePosition';
import { useStore } from 'vuex';
import { formatNetProfitLoss } from '../../formaters';
import { floatList } from '@/helpers/floatList';
import { fiorinStables } from '@/config/constants';

export default {
  name: 'PositionDetailsClosed',
  components: { ItemLabelValue },
  props: {
    position: {
      type: Object,
      required: true,
    },
    positionLoyaltyFeeValue: {
      type: [Number, String],
    },
  },
  setup(props) {
    const store = useStore();

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const { calcClosePrice, calcEntryPrice } = usePositionCalcs();
    const { t } = useI18n();
    const positionComposition = usePosition(props.position);

    const fiorinMode = computed(() => store.getters['burger/fiorinModeFlag']);

    const closedDynamicTitleLabel = computed(() => {
      return positionComposition.isHistory.value ||
        positionComposition.isClosed.value
        ? t('order.card.titles.closed')
        : t('order.card.titles.canceled');
    });

    const closeReason = computed(() => {
      const reason = t(`order.closeReasons.${props.position.close_reason}`);

      return reason.includes('order.closeReasons') ? 'Ask support' : reason;
    });

    const showDateFormat = (date) =>
      DateTime.fromISO(date).toUTC().toFormat('HH:mm, dd.MM.yy');

    return {
      ...positionComposition,
      closeReason,
      toCurrencyFormat,
      toUSDMarginFormat,
      calcClosePrice,
      calcEntryPrice,
      showDateFormat,
      getFormatedAmount,
      formatNetProfitLoss,
      closedDynamicTitleLabel,
      PositionSides,
      PositionSideTypes,
      fiorinMode,
      floatList,
      activeConnect,
      fiorinStables,
    };
  },
};
</script>
<style lang="scss"></style>
