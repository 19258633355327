<template>
  <div :class="{ notStart: !referralProgram }" class="bounty-points">
    <div
      :style="{
        width: !startProgramAndMainBalance
          ? '100%'
          : pointsProgress.percentProgress + '%',
      }"
      :class="{
        complete:
          !startProgramAndMainBalance ||
          !referralProgram ||
          !!taskForClaim ||
          pointsProgress.percentProgress >= 100,
        notStart: !referralProgram,
      }"
      class="gradient"
    />
    <div
      v-if="!!taskForClaim && !startProgramAndMainBalance"
      class="bounty-points__item notStart"
      @click="showModal(modalsByName.getBounty)"
    >
      {{ `Tap To Claim $${claimTotalAmount}` }}
    </div>
    <template v-else>
      <div
        v-if="notStartProgram"
        class="bounty-points__item notStart"
        @click="showModal(modalsByName.getBounty)"
      >
        Get Bounty To Trade For FREE
      </div>
      <div
        v-if="startProgramAndNullBalance"
        class="bounty-points__item notStart"
        @click="addFunds"
      >
        {{
          `Add Funds To Join $${
            settings.bountyInfo?.referralProgram?.targetPoints || 0
          } Challenge`
        }}
      </div>
      <div
        v-if="noStartProgramAndBountyBalance"
        class="bounty-points__item notStart"
        @click="showModal(modalsByName.getBounty)"
      >
        {{ `You've Got $${activeConnect.bountyBalance || 0.1} For Trading` }}
      </div>
      <div
        v-if="startProgramAndMainBalance"
        :class="{
          notStartInProgress:
            !referralProgram || pointsProgress.percentProgress < 12,
        }"
        class="bounty-points__item"
        @click="showModal(modalsByName.getBounty)"
      >
        {{
          `${pointsProgress?.leftPointsToWin?.toFixed(2)} Points Left To Get $${
            pointsProgress?.targetPoint
          }`
        }}
      </div>
    </template>
    <!--<div class="bounty-points__item">Tap To Claim $20</div> -->
  </div>
</template>
<script>
import { useBountyPrograms } from '@/compositions/useBountyPrograms';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useModals } from '@/modules/modals/api';
import { useStore } from 'vuex';
import { connApp } from '@/helpers/signalR';

export default {
  setup() {
    const {
      notStartProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
      pointsProgress,
      referralProgram,
    } = useBountyPrograms();
    const { showModal, modalsByName } = useModals();
    const store = useStore();
    const timer = ref(null);

    const userSettings = computed(() => store.getters['settings/uiSettings']);
    const settings = computed(() => store.getters['settings/settings']);
    const activityPointsBalance = computed(
      () => store.getters['localUiSettings/activityPointsBalance']
    );

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const newBountyTasks = computed(
      () => store.getters['bounty/newBountyTasks']
    );

    const taskForClaim = computed(() => {
      return newBountyTasks.value?.find((item) => item.status === 'NotPaid');
    });

    const claimTotalAmount = computed(() => {
      return newBountyTasks.value
        ?.filter((item) => item.status === 'NotPaid')
        .reduce((prev, current) => {
          return prev + current.amount;
        }, 0);
    });

    const pointProgress = computed(() => {
      const from = activityPointsBalance.value.balance || 0;
      const to = settings.value.bountyInfo?.referralProgram?.targetAmount || 0;
      const percentProgress = ((from / to) * 100).toFixed(2);

      return percentProgress;
    });

    const addFunds = () => {
      const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;
      frames.fiorin.postMessage({ event: 'VIEW:DEPOSIT' }, FIORIN_HOST);

      return;
    };

    onBeforeUnmount(() => {
      clearInterval(timer.value);
    });

    onMounted(async () => {
      try {
        timer.value = setInterval(async () => {
          const bountyTasks = await connApp.invoke('ListBountyTasks');
          store.dispatch('bounty/setNewBountyBalance', bountyTasks);
        }, 10000);
      } catch (err) {
        clearInterval(timer.value);
        console.log(err);
      }
      try {
        const info = await connApp.invoke('GetActivityPointsBalance');
        const bountyTasks = await connApp.invoke('ListBountyTasks');
        store.dispatch('bounty/setNewBountyBalance', bountyTasks);
        store.dispatch('localUiSettings/setActivityPointsBalance', info);
        console.log(
          'BOUNTY POINTS INFO',
          activityPointsBalance.value,
          settings.value.bountyInfo,
          pointProgress.value
        );
      } catch (err) {
        // notify({ text: getErrorDetails(err).message, type: 'info' });
      }
    });

    return {
      settings,
      claimTotalAmount,
      notStartProgram,
      referralProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
      activityPointsBalance,
      modalsByName,
      userSettings,
      pointsProgress,
      newBountyTasks,
      taskForClaim,
      addFunds,
      showModal,
      activeConnect,
    };
  },
};
</script>
<style lang="scss">
.bounty-points {
  height: 60px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #283646;

  @media screen and (min-width: 1024px) {
    height: 44px;
  }

  &__item {
    font-size: 15px;
    position: absolute;
    color: #636060;
    font-family: 'Gotham_Pro_Medium';
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 60px;
    background: linear-gradient(135deg, #000 45%, #808080 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    border-image: linear-gradient(135deg, #000 45%, #808080 50%);
    border-image-slice: 1;

    @media screen and (min-width: 1024px) {
      line-height: 44px;
    }

    &.notStart {
      -webkit-text-fill-color: initial;
      color: #000;
    }

    &.notStartInProgress {
      -webkit-text-fill-color: initial;
      color: rgba(255, 255, 255, 0.3);
      background: none;
    }
  }

  .gradient {
    position: absolute;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 0;
    top: 0;
    height: 60px;
    background: linear-gradient(to right, #2f80ed, #b2ffda);

    @media screen and (min-width: 1024px) {
      height: 44px;
    }

    &.complete {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.notStart {
      background: linear-gradient(to right, #2f80ed, #b2ffda);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
</style>
