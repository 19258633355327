<template>
  <div id="v-step-3" class="chart-container box">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width="28"
      height="28"
      class="fullscreen-btn"
      @click="goFullScreen"
    >
      <g fill="currentColor">
        <path d="M21 7v4h1V6h-5v1z"></path>
        <path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path>
        <path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path>
        <path d="M16.854 16.146l5 5-.708.708-5-5z"></path>
        <g>
          <path d="M7 21v-4H6v5h5v-1z"></path>
          <path d="M11.146 16.146l-5 5 .708.708 5-5z"></path>
        </g>
      </g>
    </svg>
    <div
      v-show="loaded"
      :id="containerId"
      :class="{
        standalone:
          wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      }"
      class="TVChartContainer"
    />
    <Loader v-show="!loaded" class="chart-container__loader" />
  </div>
</template>

<script>
import Loader from './Loader';
import { mapGetters, mapActions, useStore, mapMutations, mapState } from 'vuex';
import envConfig from '@/config';
import { PositionSides } from '@/config/constants';
import { toCurrencyFormat, getFormatedAmount } from '@/helpers/utils';
import Radio from '@/components/app/Radio';
import { useModals } from '@/modules/modals/api';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { UDFCompatibleDatafeed } from '@/helpers/chart/lib/udf-compatible-datafeed.js';
import useTheme from '@/compositions/useTheme';
import axios from 'axios';
import { computed } from 'vue';

const CHART_NAME = 'Saved';

export default {
  name: 'Chart',
  setup() {
    const { storeTheme } = useTheme();
    const store = useStore();

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      theme: storeTheme.value,
      wd: window,
      isRunningStandaloneAndroid,
      isTelegram,
    };
  },
  inject: ['tv_widget'],
  components: {
    Loader,
  },
  props: {
    containerId: {
      default: 'tv_chart_container',
      type: String,
    },
    datafeedUrl: {
      default: 'https://demo_feed.tradingview.com',
      type: String,
    },
    libraryPath: {
      default: '/charting_library_v28/charting_library/',
      type: String,
    },
    chartsStorageUrl: {
      default: process.env.VUE_APP_API_URL.slice(0, -1),
      type: String,
    },
    chartsStorageApiVersion: {
      default: 'v1.0',
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    mobile: Boolean,
  },
  data() {
    return {
      ...useModals(),
      buttonsMounted: false,
      isChartReady: false,
      initialTimer: null,
      chartLoaderInterval: null,
      loaded: false,
      init: false,
      subscribed: false,
      tvWidget: null,
      linesPositions: [],
      symbol: '',
      interval:
        localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution') ||
        '30',
      user_id: '0',
      graphButtons: {
        BID: null,
        MID: null,
        ASK: null,
      },
      colorMap: {
        Dark: {
          buy: '#509f96',
          sell: '#de5e56',
        },
        Light: {
          buy: '#509f96',
          sell: '#de5e56',
        },
      },
    };
  },
  computed: {
    ...mapState({
      savedCharts: (state) => state.graph.savedCharts,
    }),
    ...mapGetters({
      uiSetting: 'settings/uiSetting',
      activeMarket: 'markets/activeMarket',
      marketsByName: 'markets/marketsByName',
      activeMarketId: 'markets/activeMarketId',
      currentGraphType: 'graph/getCurrentGraphType',
      loading: 'graph/showOverlay',
      needRedrawLines: 'graph/redrawLines',
      reloadGraphFlag: 'graph/reloadGraphFlag',
      userId: 'user/userId',
      activeConnect: 'connectors/activeConnect',
      activeMarketPositions: 'positions/getActiveMarketPositions',
    }),
    chartTheme() {
      const themeMap = {
        light: 'Light',
        dark: 'Dark',
      };
      return themeMap[this.uiSetting('theme')];
    },
    widgetOptions() {
      return {
        client_id: 'tdxp',
        user_id: this.userId,
        datafeed: new UDFCompatibleDatafeed(
          `${envConfig.apiUrl}trading-view/udf`,
          1000
        ),
        theme: this.chartTheme === 'Twilight' ? 'Dark' : this.chartTheme,
        symbol: this.activeMarket && this.activeMarket.name,
        toolbar_bg: '#00000000',
        loading_screen: {
          backgroundColor: '#00000000',
          foregroundColor: '#00000000',
        },
        interval: this.interval,
        container: this.containerId,
        library_path: this.libraryPath,
        custom_css_url: '/cl_css/cl_css.css',
        locale: 'en',
        numeric_formatting: { decimal_sign: ',' },
        disabled_features: [
          'header_screenshot',
          'volume_force_overlay',
          'header_compare',
          'header_symbol_search',
          'header_settings',
          'timeframes_toolbar',
          'display_market_status',
          'create_volume_indicator_by_default',
          'save_chart_properties_to_local_storage',
        ],
        enabled_features: ['use_localstorage_for_settings'],
        charts_storage_url: this.chartsStorageUrl,
        charts_storage_api_version: this.chartsStorageApiVersion,
        fullscreen: this.fullscreen,
        autosize: true,
        load_last_chart: true,
        overrides: {
          'paneProperties.backgroundType': 'solid', // or "gradient"
          'paneProperties.background': 'rgba(0, 0, 0, 0)',
          'scalesProperties.backgroundColor': 'rgba(0, 0, 0, 0)',
          'mainSeriesProperties.areaStyle.color1': 'rgb(255, 0, 0)',
          'mainSeriesProperties.areaStyle.color2': 'rgb(0,255,0)',
          'scalesProperties.lineColor': 'rgba(0, 0, 0, 0)', // main border
          'scalesProperties.textColor': '#AAA',

          'paneProperties.crossHairProperties.color': '#ccc',
          'paneProperties.crossHairProperties.width': 1,
          'paneProperties.crossHairProperties.style': 0,
          'mainSeriesProperties.showPriceLine': true,
          'mainSeriesProperties.priceLineColor': '#ccc',

          'paneProperties.legendProperties.showSeriesTitle': false, //hide market before OHLC
          // grids on graph
          'paneProperties.vertGridProperties.color': '#cccccc00',
          'paneProperties.vertGridProperties.style': 1,
          'paneProperties.horzGridProperties.color': '#cccccc00',
          'paneProperties.horzGridProperties.style': 1,

          // candles color
          'mainSeriesProperties.candleStyle.downColor': 'rgb(222, 94, 86)',
          'mainSeriesProperties.candleStyle.upColor': 'rgb(80, 159, 150)',
          'mainSeriesProperties.candleStyle.borderDownColor':
            'rgb(222, 94, 86)',
          'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(80, 159, 150)',
          'mainSeriesProperties.candleStyle.wickDownColor': 'rgb(222, 94, 86)',
          'mainSeriesProperties.candleStyle.wickUpColor': 'rgb(80, 159, 150)',
          // Hollow Candles styles
          'mainSeriesProperties.hollowCandleStyle.downColor':
            'rgb(222, 94, 86)',
          'mainSeriesProperties.hollowCandleStyle.upColor': 'rgb(80, 159, 150)',
          'mainSeriesProperties.hollowCandleStyle.borderDownColor':
            'rgb(222, 94, 86)',
          'mainSeriesProperties.hollowCandleStyle.borderUpColor':
            'rgb(80, 159, 150)',
          'mainSeriesProperties.hollowCandleStyle.wickDownColor':
            'rgb(222, 94, 86)',
          'mainSeriesProperties.hollowCandleStyle.wickUpColor':
            'rgb(80, 159, 150)',

          // Heikin Ashi styles
          'mainSeriesProperties.haStyle.upColor': 'rgb(80, 159, 150)',
          'mainSeriesProperties.haStyle.downColor': 'rgb(222, 94, 86)',
          'mainSeriesProperties.haStyle.borderUpColor': 'rgb(80, 159, 150)',
          'mainSeriesProperties.haStyle.borderDownColor': 'rgb(222, 94, 86)',

          // Bar styles
          'mainSeriesProperties.barStyle.downColor': 'rgb(222, 94, 86)',
          'mainSeriesProperties.barStyle.upColor': 'rgb(80, 159, 150)',
          'symbolWatermarkProperties.transparency': 10,
        },
      };
    },
  },
  watch: {
    userId(userId) {
      this.interval = this.getInterval();
      if (userId) {
        this.user_id = userId;
        this.fetchActiveMarketSavedChart();
        this.init = false;
      }
    },
    currentGraphType() {
      this.interval = this.getInterval();
      if (window.TradingView) {
        const subscribers =
          this.tvWidget._options.datafeed._dataPulseProvider._subscribers;

        const currentSubscriberKey = Object.keys(subscribers).find((key) =>
          key.includes(this.activeMarket.name)
        );

        const currentSubscriber = subscribers[currentSubscriberKey];

        currentSubscriber?.onResetCacheNeededCallback?.();

        this.tvWidget.activeChart().resetData();

        setTimeout(() => {
          this.tvWidget.activeChart().setSymbol(this.activeMarket.name);
        }, 10);
      }
    },
    activeMarketId(_, oldId) {
      if (this.mobile && oldId) {
        this.loaded = false;

        setTimeout(() => {
          this.loaded = true;
        }, 500);
      }

      if (this.tvWidget && this.tvWidget.chart && this.activeMarket) {
        try {
          const cb = () => {
            this.drawGraph();
            this.setRedrawLinesFlag();
          };

          this.tvWidget.setSymbol(
            this.activeMarket.name,
            this.getInterval(),
            cb
          );
        } catch (e) {
          this.drawGraph();
        }
      }
    },
    chartTheme(_, oldTheme) {
      if (oldTheme) {
        this.getAndSetTheme();
      }
    },
    reloadGraphFlag(value) {
      if (value) {
        try {
          this.drawGraph();
        } catch (err) {
          console.error(err);
        }
      }
    },
    needRedrawLines(value) {
      if (value) {
        setTimeout(() => {
          Radio.$emit('redraw-position-lines');
          this.redrawLines();
          this.clearRedrawLinesFlag();
        }, 1500);
      }
    },
  },
  mounted() {
    this.initialTimer = setInterval(() => {
      if (window.TradingView && this.activeMarket) {
        this.drawGraph();
        clearInterval(this.initialTimer);
      }
    }, 15);
    Radio.$on('socket-init', this.drawGraph);
    Radio.$on('redraw-position-lines', this.redrawLines);

    document.body.addEventListener('fullscreenchange', this.toggleFullscreen);
  },
  beforeUnmount() {
    clearInterval(this.initialTimer);
    Radio.$off('socket-init', this.drawGraph);
    Radio.$off('redraw-position-lines', this.redrawLines);
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }

    document.body.removeEventListener(
      'fullscreenchange',
      this.toggleFullscreen
    );
  },
  methods: {
    ...mapActions({
      setCurrentPosition: 'positions/setCurrentPosition',
      changeGraphType: 'graph/changeGraphType',
      setRedrawLinesFlag: 'graph/setRedrawLinesFlag',
      clearRedrawLinesFlag: 'graph/clearRedrawLinesFlag',
    }),
    ...mapMutations({
      updateActiveMarket: 'markets/UPDATE_ACTIVE_MARKET',
      setSavedChart: 'graph/SET_SAVED_CHART',
    }),
    getInterval() {
      return (
        this.savedCharts[this.activeMarketId] ||
        localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution') ||
        '30'
      );
    },
    getAndSetTheme() {
      this.init = false;
      this.drawGraph(true);
    },
    async fetchActiveMarketSavedChart() {
      try {
        if (!this.userId) {
          return;
        }

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}v1.0/charts?client=${this.widgetOptions.client_id}&user=${this.userId}`
        );

        const chart = this.selectLastSavedChart(
          data?.data.map((c) => ({
            ...c,
            short_symbol: c.symbol,
            interval: c.resolution,
          }))
        );

        if (!chart) {
          return;
        }

        const { interval } = chart;
        const lastSavedResolution = this.getInterval();

        if (interval === lastSavedResolution) {
          const loadInterval = setInterval(() => {
            if (!this.isChartReady) {
              return;
            }

            clearInterval(loadInterval);

            this.loadChart({
              id: chart.id,
              image_url: `${chart.id}`,
              interval: chart.resolution,
              modified_iso: chart.timestamp,
              name: chart.name,
              short_symbol: chart.symbol,
            });
          }, 50);
        }
      } catch (e) {
        console.error({ e });
      }
    },
    selectLastSavedChart(savedCharts = []) {
      const lastSavedResolution = this.getInterval();

      for (let i = savedCharts.length - 1; i >= 0; i--) {
        const savedChart = savedCharts[i];
        const sameName = true;
        // savedChart.name === this.activeMarket.name &&
        // savedChart.symbol === this.activeMarket.name;

        const sameInterval = lastSavedResolution === savedChart.resolution;

        if (sameName && sameInterval) {
          return savedChart;
        }
      }
    },
    saveChartToServer(widget) {
      widget.saveChartToServer(
        () => {},
        () => {},
        {
          chartName: CHART_NAME,
        }
      );
    },
    saveCurrentChart(widget) {
      const loadInterval = setInterval(() => {
        if (!this.isChartReady) {
          return;
        }

        if (this.activeMarket.name !== widget.activeChart().symbol()) {
          widget
            .activeChart()
            .setSymbol(this.activeMarket.name, this.interval, () =>
              this.saveChartToServer(widget)
            );
        } else {
          this.saveChartToServer(widget);
        }

        clearInterval(loadInterval);
      }, 50);
    },
    loadChart(savedChart) {
      this.tvWidget.loadChartFromServer?.(savedChart);

      this.applyThemeOverrides();
    },
    applyThemeOverrides() {
      this.tvWidget.applyOverrides({
        'paneProperties.background': 'rgba(0, 0, 0, 0)',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.vertGridProperties.color': '#cccccc00',
        'paneProperties.horzGridProperties.color': '#cccccc00',
        'mainSeriesProperties.candleStyle.downColor': 'rgb(222, 94, 86)',
        'mainSeriesProperties.candleStyle.upColor': 'rgb(80, 159, 150)',
        'mainSeriesProperties.candleStyle.borderDownColor': 'rgb(222, 94, 86)',
        'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(80, 159, 150)',
        'mainSeriesProperties.candleStyle.wickDownColor': 'rgb(222, 94, 86)',
        'mainSeriesProperties.candleStyle.wickUpColor': 'rgb(80, 159, 150)',
        'mainSeriesProperties.hollowCandleStyle.downColor': 'rgb(222, 94, 86)',
        'mainSeriesProperties.hollowCandleStyle.upColor': 'rgb(80, 159, 150)',
        'mainSeriesProperties.hollowCandleStyle.borderDownColor':
          'rgb(222, 94, 86)',
        'mainSeriesProperties.hollowCandleStyle.borderUpColor':
          'rgb(80, 159, 150)',
        'mainSeriesProperties.hollowCandleStyle.wickDownColor':
          'rgb(222, 94, 86)',
        'mainSeriesProperties.hollowCandleStyle.wickUpColor':
          'rgb(80, 159, 150)',
        'mainSeriesProperties.barStyle.downColor': 'rgb(222, 94, 86)',
        'mainSeriesProperties.barStyle.upColor': 'rgb(80, 159, 150)',
      });
    },
    autoSaveCallback() {
      const { interval } = this.tvWidget.symbolInterval();
      console.warn('on auto save', this.tvWidget.symbolInterval());
      this.setSavedChart({ key: this.activeMarketId, value: interval });

      this.saveCurrentChart(this.tvWidget);
    },
    async drawGraph(loaded = false) {
      if (
        this.init ||
        this.tvWidget ||
        !document.getElementById(this.containerId)
      ) {
        return;
      }

      this.loaded = loaded;
      this.isChartReady = false;

      const tvWidget = new this.tv_widget(this.widgetOptions);

      setTimeout(() => {
        this.init = true;
      }, 500);

      if (this.userId) {
        await this.fetchActiveMarketSavedChart();
      }

      tvWidget.onChartReady(() => {
        this.isChartReady = true;

        this.tvWidget = tvWidget;

        this.applyThemeOverrides();
        tvWidget.subscribe('mouse_down', () => {
          this.$emit('onGraphClick');
        });

        if (this.subscribed) {
          tvWidget.unsubscribe('onAutoSaveNeeded', this.autoSaveCallback);
        }

        tvWidget.subscribe('onAutoSaveNeeded', this.autoSaveCallback);
        this.subscribed = true;

        tvWidget.subscribe('chart_load_requested', (savedData) => {
          const { resolution } = savedData;

          if (this.marketsByName[savedData.symbol].id !== this.activeMarketId) {
            // this.updateActiveMarket(this.marketsByName[savedData.symbol]);
            savedData.symbol = this.activeMarket.name;

            this.tvWidget.setSymbol(this.activeMarket.name);
          }

          const lastSavedResolution = this.getInterval();

          if (resolution !== lastSavedResolution) {
            this.interval = lastSavedResolution;
            Object.assign(savedData, { resolution: lastSavedResolution });
          }
        });

        tvWidget.subscribe('chart_loaded', () => {
          this.applyThemeOverrides();

          setTimeout(() => {
            this.loaded = true;
          }, 500);

          this.interval = this.getInterval();
        });

        this.tvWidget.headerReady().then(() => {
          this.applyThemeOverrides();
          setTimeout(() => {
            this.loaded = true;
          }, 500);

          if (!this.buttonsMounted) {
            this.createGraphButton(tvWidget, 'BID PRICE', 'BID', 'BID');
            this.createGraphButton(tvWidget, 'MID PRICE', 'MID', 'MID');
            this.createGraphButton(tvWidget, 'ASK PRICE', 'ASK', 'ASK');
          }
        });

        let interval = setInterval(() => {
          if (this.tvWidget && this.tvWidget.chart()) {
            this.clearRedrawLinesFlag();
            setTimeout(this.setRedrawLinesFlag, 10);
            clearInterval(interval);
          }
        }, 10);
      });
    },
    createGraphButton(tvWidget, title, graphType, buttonText) {
      this.buttonsMounted = true;
      const button = tvWidget.createButton({
        align: 'left',
      });
      button.setAttribute('title', title);
      button.classList.add('graph-type');
      button.innerHTML = buttonText;

      this.graphButtons[graphType] = button;

      if (this.currentGraphType === graphType) {
        button.classList.add('_active-graph-type');
      } else {
        button.classList.add('_active-graph-type-disable');
      }

      button.addEventListener('click', () => {
        if (this.currentGraphType === graphType) {
          this.changeGraphType('MID');

          this.graphButtons.BID.classList.add('_active-graph-type-disable');
          this.graphButtons.ASK.classList.add('_active-graph-type-disable');

          this.graphButtons.MID.classList.remove('_active-graph-type-disable');
        } else {
          this.changeGraphType(graphType);
          this.graphButtons.BID.classList.add('_active-graph-type-disable');
          this.graphButtons.MID.classList.add('_active-graph-type-disable');
          this.graphButtons.ASK.classList.add('_active-graph-type-disable');

          this.graphButtons[graphType].classList.remove(
            '_active-graph-type-disable'
          );
          this.graphButtons[graphType].classList.add('_active-graph-type');
        }
      });
    },
    openPositionCard(payload) {
      this.setCurrentPosition(payload);

      this.showModal(this.modalsByName.positionCard);
    },
    redrawLines() {
      const vm = this;
      const theme = this.chartTheme;
      const colors = {
        proposed: '#888',
        buy: this.colorMap[theme].buy,
        sell: this.colorMap[theme].sell,
        light: '#fff',
        dark: '#000',
      };
      const mainColor = theme === 'Light' ? colors.light : colors.dark;
      const positions = this.activeMarketPositions || [];
      try {
        for (let line of this.linesPositions) {
          line.remove();
        }
        this.linesPositions = [];
      } catch (e) {
        this.linesPositions = [];
      }

      if (this.tvWidget && this.tvWidget.chart) {
        for (let position of positions) {
          const secondColor =
            position.state === 'proposed'
              ? colors.proposed
              : position.side === PositionSides.BUY
              ? colors.buy
              : colors.sell;
          try {
            let line = this.tvWidget.chart().createOrderLine();
            this.linesPositions.push(line);
            line
              .setLineLength(100)
              .setLineStyle(position.state === 'proposed' ? 2 : 0)
              .setLineWidth(0)
              .setLineColor(secondColor)
              .setBodyTextColor(secondColor)
              .setBodyBorderColor(secondColor)
              .setBodyBackgroundColor(mainColor)
              .setQuantityBorderColor(secondColor)
              .setQuantityBackgroundColor(secondColor)
              .setQuantityTextColor(mainColor)
              .setQuantityFont('bold 16pt Verdana')
              .setBodyFont('bold 16pt Verdana')
              .setCancelButtonBorderColor(secondColor)
              .setCancelButtonBackgroundColor(mainColor)
              .setCancelButtonIconColor(secondColor)
              .setPrice(position.entry_price)
              .onModify({ number: position.id }, () => {
                localStorage.setItem('hideTradeButton', true);
                vm.openPositionCard({ position });
              })
              .setQuantity(
                `    $${
                  position.base_currency === 'USD'
                    ? getFormatedAmount(position.amount, true)
                    : toCurrencyFormat(position.amount, null, 4)
                }    `
              )
              .setText('')
              .setEditable(true);
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    async goFullScreen() {
      if (!document.fullscreenElement || !document.exitFullscreen) {
        await document.body.requestFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
    async exitFullscreen() {
      document
        .querySelector('.chart-container')
        ?.classList.remove('fullscreen');

      if (!document.fullscreenElement || !document.exitFullscreen) {
        return;
      }
      await document.exitFullscreen();
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document
          .querySelector('.chart-container')
          ?.classList.remove('fullscreen');
      } else if (document.exitFullscreen) {
        document.querySelector('.chart-container')?.classList.add('fullscreen');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: 100%;
  border-top: 1px solid #eaeaea;

  &.standalone {
    height: calc(100% - 30px);
  }
}

.chart-container {
  position: relative;
  height: 350px;

  &.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
  }

  .fullscreen-btn {
    position: absolute;
    right: 10px;
    top: 6px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media screen and (max-width: 1023px) {
    height: calc(100% - 52px);
  }

  @media screen and (min-width: 1440px) {
    height: 50vh;
  }
}

body.dark {
  .TVChartContainer {
    border-top: 1px solid #5a5f755c;
  }
}
</style>
