import Connector from './Connector';
import store from '@/store';
import notify from '@/plugins/notify';
const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

let checkedAuth = false;
export default class FiorinConnector extends Connector {
  constructor(opts) {
    super(opts);
    this.stopRedirect = opts.stopRedirect;
    this.provider = 'Fiorin';
    this.accessToken = opts.accessToken;
    this.satoshiBalance = 0;
    this.fiorinUserName = '';
    this.paymailAddress = '';
    this.serverInfo = {};
    this.isOtherWeb3 = '';
    this.pendingUsd = 0;
    this.bountyBalance = 0;
    this.balancesLog = [];
  }

  async fetchBalance() {}

  async fetchProfile() {}

  async sendFiorinTx() {}

  connect({ isMetamask, isTrustWallet, isWalletConnect, isSignup } = {}) {
    let loginRequired = false;

    const frame = document.createElement('iframe');

    const setFrameInfo = (width, height, style) => {
      frame.setAttribute('width', width);
      frame.setAttribute('height', height);
      frame.setAttribute('id', 'fiorinFrame');

      if (style || style === '') {
        frame.setAttribute('style', style);
      }
    };

    const openFrame = () => {
      setFrameInfo(
        '100%',
        '100%',
        'position: fixed; bottom: 0px; left: 0; z-index: 100100;'
      );
    };

    const closeFrame = () => {
      setFrameInfo('0', '0', 'display: none;');
    };

    const trySendStorePacketAndOpenFrame = () => {
      const packet = localStorage.getItem('_fp');
      if (packet) {
        frames.fiorin.postMessage(
          { event: 'STORE', payload: packet },
          FIORIN_HOST
        );

        setTimeout(openFrame, 500); // ОТСЮДА

        return true;
      }

      return false;
    };

    const [, hostUrl] = [
      [isMetamask, `${FIORIN_HOST}/ethereum?wallet=metamask`],
      [isTrustWallet, `${FIORIN_HOST}/ethereum?wallet=trust`],
      [isWalletConnect, `${FIORIN_HOST}/ethereum`],
      [isSignup, `${FIORIN_HOST}/sign-up`],
      [true, FIORIN_HOST],
    ].find(([predicate]) => predicate);

    frame.setAttribute('src', hostUrl);
    frame.setAttribute('name', 'fiorin');
    frame.setAttribute('frameBorder', '0');
    frame.setAttribute('allow', 'clipboard-read; clipboard-write');
    frame.setAttribute('id', 'fiorinFrame');

    // applicable to safari only, both mac and phone
    // if (document.hasStorageAccess) {
    //   frame.setAttribute(
    //     'sandbox',
    //     'allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-modals'
    //   );
    // }

    closeFrame();

    // document.body.appendChild(frame);
    document.body.appendChild(frame);

    const onMessageHandler = async (event) => {
      if (event.origin !== FIORIN_HOST) {
        return;
      }
      console.log('EVENT', event.data.event, event.data);
      if (event.data.event === 'OPEN_ME') {
        openFrame();
      }

      if (event.data.event === 'AUTH_REQUIRED') {
        setTimeout(() => {
          if (!this.accessToken && !checkedAuth) {
            if (!trySendStorePacketAndOpenFrame()) {
              openFrame();
            }
          }
        }, 3000);
      }

      if (event.data.event === 'LOGIN_REQUIRED') {
        loginRequired = true;

        setTimeout(() => {
          if (
            !store.getters['connectors/activeConnect']?.accessToken &&
            !this.accessToken &&
            !checkedAuth
          ) {
            if (!trySendStorePacketAndOpenFrame()) {
              openFrame();
            }
          }
        }, 3000);
      }

      if (event.data.event === 'LOGIN_FAILED') {
        notify({
          text: 'Fiorin Failed. Fiorin login is required for authorization',
          type: 'info',
        });
        store.dispatch('burger/fiorinMode', false);
        store.dispatch('connectors/setActiveConnect', {});

        // setTimeout(() => {
        //   // store.dispatch('burger/logout', false, { root: true });
        //   /// window.location.reload();
        // }, 2500);
      }

      if (event.data.event === 'ADDRESS') {
        this.paymailAddress = event.data.payload.address;
        localStorage.setItem(
          'fiorinPaymail',
          `${+new Date()} ${JSON.stringify(event.data)}`
        );
      }

      if (event.data.event === 'AUTHORIZED') {
        loginRequired = false;
        this.balancesLog.push(`|${+new Date()}|`);
        checkedAuth = true;
        // setTimeout(() => {
        //   closeFrame();
        // }, 300);
        this.fiorinUserName = event.data.payload.email;
        closeFrame();
        frames.fiorin.postMessage({ event: 'GET_TOKEN' }, FIORIN_HOST);
        frames.fiorin.postMessage(
          { event: 'GET_IDENTITY_PROVIDER' },
          FIORIN_HOST
        );
        frames.fiorin.postMessage({ event: 'GET_BALANCE' }, FIORIN_HOST);
        frames.fiorin.postMessage({ event: 'GET_ADDRESS' }, FIORIN_HOST);
      }

      if (event.data.event === 'IDENTITY_PROVIDER') {
        this.isOtherWeb3 = event.data.payload.provider;
      }

      if (event.data.event === 'LOGOUT_COMPLETED') {
        const frame = document.getElementById('fiorinFrame');

        if (frame) {
          window.removeEventListener('message', onMessageHandler);
          store.dispatch('connectors/setActiveConnect', {});
          store.dispatch('connectors/setUserName', '', { root: true });
          localStorage.removeItem('dxsLogined');
          frame.parentNode.removeChild(frame);
          window.location.reload();
        }
      }

      if (['DECLINED', 'CLOSED'].includes(event.data.event)) {
        closeFrame();

        if (loginRequired) {
          store.dispatch('burger/fiorinMode', true);
          store.dispatch('connectors/setActiveConnect', {});
          store.dispatch('connectors/setUserName', '', { root: true });
          localStorage.removeItem('dxsLogined');
          const frame = document.getElementById('fiorinFrame');
          if (frame) {
            window.removeEventListener('message', onMessageHandler);
            frame.parentNode.removeChild(frame);
          }
        }
        // store.dispatch('connectors/setActiveConnect', {});
        // store.dispatch('burger/logout', false, { root: true });
      }

      if (event.data.event === 'ERROR') {
        notify({
          text: event.data.payload.message || event.data.payload.error,
          type: 'info',
        });
      }

      // USER EVENT TRIGER
      if (event.data.event === 'REFILL_COMPLETED') {
        store.dispatch(
          'fiorin/setFiorinLastTx',
          event.data.payload.txs.reverse()[0]
        );
      }

      if (event.data.event === 'TOKEN') {
        this.accessToken = event.data.payload.token;
        frames.fiorin.postMessage({ event: 'GET_BALANCE' }, FIORIN_HOST);
        frames.fiorin.postMessage({ event: 'GET_ADDRESS' }, FIORIN_HOST);
        frames.fiorin.postMessage(
          { event: 'GET_PENDING_BALANCE' },
          FIORIN_HOST
        );
        frames.fiorin.postMessage({ event: 'GET_BOUNTY_BALANCE' }, FIORIN_HOST);

        // this.satoshiBalanceUsd = '0';
      }

      if (event.data.event === 'WITHDRAWAL') {
        notify({
          text: 'Sending funds...',
          type: 'info',
        });
      }

      if (event.data.event === 'DEPOSIT') {
        notify({
          text: 'Receiving funds...',
          type: 'info',
        });
      }

      if (event.data.event === 'PENDING_BALANCE') {
        this.pendingUsd = event.data.payload.pendingBalance;
        await store.dispatch('connectors/setActiveConnect', {
          ...this,
          pendingUsd: event.data.payload.pendingBalance,
          serverInfo: store.getters['connectors/activeConnect']?.serverInfo,
        });
      }

      if (event.data.event === 'BOUNTY_BALANCE') {
        this.balancesLog.push(`b${event.data.payload.amount}`);
        this.bountyBalance = event.data.payload.amount;

        await store.dispatch('connectors/setActiveConnect', {
          ...this,
          bountyBalance: event.data.payload.amount,
          serverInfo: store.getters['connectors/activeConnect']?.serverInfo,
        });
        store.dispatch('bounty/setBountyBalance', this.bountyBalance);
      }

      if (event.data.event === 'ACTIVE_TOKENS') {
        this.activeTokens = event.data.payload.tokens;

        const totalAmountUsd = event.data.payload.tokens.reduce(
          (prev, item) => {
            return prev + item.amountUsd;
          },
          0
        );
        console.log('totalAmountUsd', totalAmountUsd);

        await store.dispatch('connectors/setActiveConnect', {
          ...this,
          totalAssetsUsd: totalAmountUsd,
          activeTokens: event.data.payload.tokens,
          serverInfo: store.getters['connectors/activeConnect']?.serverInfo,
        });
      }

      if (event.data.event === 'BALANCE') {
        this.balancesLog.push(`a${event.data.payload.amount}`);
        loginRequired = false;
        const amount =
          event.data.payload.amount == event.data.payload.amountUsd
            ? event.data.payload.amount
            : event.data.payload.amountUsd;
        this.satoshiBalanceUsd = amount;
        console.log('amount available', amount);
        this.tokenId = event.data.payload.tokenId;
        this.tokenIdCurrency = event.data.payload.currency;
        this.tokenIdName = event.data.payload.name;

        this.serverInfo = store.getters['connectors/activeConnect']?.serverInfo;

        store.dispatch('burger/fiorinMode', true);
        await store.dispatch('connectors/setActiveConnect', {
          ...this,
          satoshiBalanceUsd: amount,
          tokenId: event.data.payload.tokenId,
          tokenIdCurrency: event.data.payload.currency,
          tokenIdName: event.data.payload.name,
          serverInfo: store.getters['connectors/activeConnect']?.serverInfo,
        });

        if (localStorage.getItem('needAuthReload')) {
          localStorage.removeItem('needAuthReload');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        if (!this.stopRedirect) {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        }
      }

      if (event.data.event === 'STORE') {
        localStorage.setItem('_fp', event.data.payload);
      }

      if (event.data.event === 'OPEN_LINK') {
        window.open(
          event.data.payload.url,
          event.data.payload.target,
          event.data.payload.features
        );
      }
    };

    window.addEventListener('message', onMessageHandler);
    return this;
  }
}
