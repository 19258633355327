import axios from 'axios';
import store from '@/store';
import config from '@/config';
import { userProviders, refreshTokenUpfrontMs } from '.';

export async function startAuth() {
  await store.dispatch('dotwallet/getClientId');
}

function transformTokenData(data) {
  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
    expirationDate: data.expiresAtUtc,
  };
}

export async function refreshToken() {
  const { refreshToken } = store.getters['user/authInfoByProvider'](
    userProviders.dotWallet
  );
  try {
    let res = await axios.post(
      `${config.apiUrl}wallets/dotwallet/token/refresh`,
      { refreshToken }
    );
    if (res.data) {
      const data = transformTokenData(res.data);
      store.commit('user/UPDATE_AUTH_DATA', {
        data,
        provider: userProviders.dotWallet,
      });
    }
  } catch (e) {
    store.commit('user/UPDATE_AUTH_DATA', {
      data: {},
      provider: userProviders.dotWallet,
    });
    console.error(e);
  }
}

export async function authorizeCode(code) {
  try {
    let result = await axios.post(`${config.apiUrl}wallets/dotwallet/token`, {
      code: code,
      redirectUri: config.uiUrl,
    });
    if (result.data) {
      store.commit('user/UPDATE_AUTH_DATA', {
        data: result.data,
        provider: userProviders.dotWallet,
      });
    } else {
      console.error('No data in DW authorize code');
    }
  } catch (e) {
    console.error('Error in DW authorize code', e);
  }
}

export async function getUserBalance() {
  try {
    let { accessToken, expirationDate } = store.getters[
      'user/authInfoByProvider'
    ](userProviders.dotWallet);
    if (
      new Date().valueOf() + refreshTokenUpfrontMs >
      new Date(expirationDate).valueOf()
    ) {
      await refreshToken();
      ({ accessToken } = store.getters['user/authInfoByProvider'](
        userProviders.dotWallet
      ));
    }
    if (!accessToken) {
      await store.dispatch('dotwallet/getClientId');
      return;
    }
    let result = await axios.get(
      `${config.apiUrl}wallets/dotwallet/user/balance`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let { total } = result.data;
    store.commit('user/SET_SATOSHI_BALANCE', Math.round(1e8 * total));
  } catch (e) {
    console.error(e);
  }
}
