import { userProviders } from '../api';
import onConnectPlugin from './onConnectPlugin';
import { parseJwt } from '@/helpers/utils';
import bsvPrice from '@/compositions/bsvPrice';
import BigNumber from 'bignumber.js';
import { PositionTypes } from '@/modules/positions/helpers';
import store from '@/store';

const state = () => {
  return {
    authData: {},
    provider: '',
    satoshiBalance: 0,
    satoshiBalanceUsd: 0,
    truedexAuth: {},
    marketFavourites: [],
    wsConfig: {},
  };
};

const getters = {
  getAccountBalance(state, getters, rootState, rootGetters) {
    const activeConnect = rootGetters['connectors/activeConnect'];
    const fiorinMode = rootGetters['burger/fiorinModeFlag'];
    const bountyProgramAvailable =
      rootGetters['settings/settings']?.bountyProgramAvailable;

    let bsvAmount =
      activeConnect && activeConnect.provider
        ? (activeConnect.satoshiBalance || activeConnect.satoshiBalanceUsd) *
            (!activeConnect.provider ||
            activeConnect.provider === userProviders.fiorin
              ? 1
              : bsvPrice()) || 0
        : 990;

    if (activeConnect.provider === userProviders.fiorin) {
      bsvAmount = activeConnect.totalAssetsUsd;
    }

    // DEMO CALCS START
    let hold_on_pending = 0;
    let hold_on_positions = 0;
    let unrealizedPL = 0;
    let risk = 0;
    const exchangeRate =
      1 / (!activeConnect.provider || fiorinMode ? 1 : bsvPrice());

    const bountyBalance = fiorinMode
      ? bountyProgramAvailable &&
        !store.getters['settings/uiSettings'].turnBountyPoints
        ? activeConnect.bountyBalance // rootGetters['bounty/balance']
        : 0
      : 0;

    const openedPositions =
      rootGetters['positions/getPositionsByType'][PositionTypes.open] || [];
    const pendingPositions =
      rootGetters['positions/getPositionsByType'][PositionTypes.pending] || [];

    pendingPositions.forEach((pos) => {
      hold_on_pending += !isNaN(pos.margin) ? pos.margin : 0;
    });

    openedPositions.forEach((pos) => {
      const exRate = pos.open_exchange_rate != 1 ? exchangeRate : 1;
      // unrealizedPL += !isNaN(pos.pl) ? pos.pl : 0;
      unrealizedPL += !isNaN(+pos.pl) ? +pos.pl * exRate : 0;

      hold_on_positions += !isNaN(+pos.margin) ? +pos.margin : 0;
      let stop_loss = +pos.stop_loss_price ? +pos.stop_loss : 80;

      if (80 < stop_loss) {
        stop_loss = 80;
      }

      if (!isNaN(pos.margin)) {
        risk += (stop_loss * +pos.margin) / 100;
      }
    });
    // DEMO CALCS END

    const incomingBalance = rootGetters['positions/getIncomingBalance'];

    const userTradeInfo = rootGetters['session/userTradeInfo'];

    const demoTotalWithPL = BigNumber(
      +hold_on_pending +
        +hold_on_positions +
        +unrealizedPL +
        +bsvAmount +
        +bountyBalance +
        +incomingBalance
    ).toFixed();

    const unrealizedPlValue = userTradeInfo.pl_b;

    // const unrealizedPlValue =
    //   userTradeInfo.pl_b /
    //   (activeConnect.provider === userProviders.fiorin ? 1 : bsvPrice());

    return {
      available: bsvAmount,
      bounty: bountyBalance,
      hold_on_total: activeConnect.provider
        ? userTradeInfo.m_b
        : hold_on_pending + hold_on_positions,
      risk: activeConnect.provider ? userTradeInfo.r_b : risk,
      unrealizedPL: activeConnect.provider
        ? BigNumber(unrealizedPlValue).toFixed()
        : BigNumber(unrealizedPL).toFixed(),
      total: activeConnect.provider
        ? BigNumber(userTradeInfo.m_b + +bsvAmount).toFixed()
        : BigNumber(
            +hold_on_pending + +hold_on_positions + +bsvAmount
          ).toFixed(),
      totalWithPL: activeConnect.provider
        ? BigNumber(
            (userTradeInfo.m_b || 0) +
              (unrealizedPlValue || 0) +
              +bsvAmount +
              +bountyBalance +
              +incomingBalance
          ).toFixed()
        : demoTotalWithPL,
    };
  },
  authInfo: (state) => {
    return state.authData[state.provider] || {};
  },
  authInfoByProvider: (state) => (provider) => {
    return state.authData[provider] || {};
  },
  provider: (state) => state.provider,
  satoshiBalance: (state) => state.satoshiBalance,
  truedexAuth: (state) => state.truedexAuth,
  userId: (state) => state.truedexAuth?.userId,
  userName: (state) => state.truedexAuth?.providerId,
  wsConfig: (state) => state.wsConfig,
  loginPaymail(state, getters, rootState, rootGetters) {
    // to use in payments
    let provider = getters.provider;
    let ret;
    switch (provider) {
      case userProviders.moneyButton:
        // eslint-disable-next-line no-case-declarations
        let uid =
          parseJwt(rootGetters['connectors/activeConnect'].accessToken).sub +
          '@moneybutton.com';
        ret = uid;
        break;
      case userProviders.relayX:
        // eslint-disable-next-line no-case-declarations
        let relayxPaymail =
          rootGetters['connectors/activeConnect'].serverInfo.providerId +
          '@relayx.io';
        ret = relayxPaymail || ret;
        break;
      case userProviders.dotWallet:
        // eslint-disable-next-line no-case-declarations
        let uname = getters.userName;
        ret = uname + '@dotwallet.com';
        break;
      case userProviders.fiorin:
        // eslint-disable-next-line no-case-declarations
        ret = rootGetters['connectors/activeConnect'].paymailAddress;
        break;
      case userProviders.handCash:
        // eslint-disable-next-line no-case-declarations
        const profile =
          rootGetters['connectors/activeConnect'].profile.publicProfile;
        ret = profile && profile.paymail;
        // console.log('hand cash name 2', ret)
        break;
      default:
        break;
    }
    return ret;
  },
};

const types = {
  UPDATE_AUTH_DATA: 'UPDATE_AUTH_DATA',
  SET_PROVIDER: 'SET_PROVIDER',
  SET_SATOSHI_BALANCE: 'SET_SATOSHI_BALANCE',
  SET_TRUEDEX_AUTH: 'SET_TRUEDEX_AUTH',
  SET_WS_CONFIG: 'SET_WS_CONFIG',
};

export const userMutations = types;

const mutations = {
  [types.SET_PROVIDER](state, value) {
    state.provider = value;
  },
  [types.UPDATE_AUTH_DATA](state, { data, provider }) {
    state.authData[provider] = data;
  },
  [types.SET_SATOSHI_BALANCE](state, value) {
    state.satoshiBalance = value || 0;
  },
  [types.SET_TRUEDEX_AUTH](state, value) {
    state.truedexAuth = value;
  },
  [types.SET_WS_CONFIG](state, config) {
    state.wsConfig = config;
  },
};

const actions = {
  setTruedexAuth({ commit, getters }, value) {
    commit(types.SET_TRUEDEX_AUTH, value);
    if (value) {
      // write paymail, userId to current account in LS tdxp.accounts
      commit(types.UPDATE_AUTH_DATA, {
        data: Object.assign(getters.authInfo, {
          paymail:
            value.providerId +
            `@${value.provider
              .split('')
              .filter((c) => c === c.toUpperCase())
              .join('')}`,
          userId: value.userId,
        }),
        provider: value.provider,
      });
    }
  },
  logout({ getters, commit }) {
    commit(types.SET_TRUEDEX_AUTH, null);
    commit(types.UPDATE_AUTH_DATA, { data: null, provider: getters.provider });
    commit(types.SET_PROVIDER, userProviders.demo);
  },
};

export const cachedPaths = ['provider', 'authData'];
export { onConnectPlugin };
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
