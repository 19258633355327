// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/svg/gplay-light.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/svg/close-light.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/svg/gplay-twilight.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/svg/close-twilight.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/svg/gplay-dark.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@/assets/svg/close-dark.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container[data-v-e8d914be] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 0;\n  transition: height 0.3s;\n  overflow: hidden;\n}\n.container span[data-v-e8d914be] {\n  font: 15px \"Gotham_Pro_Regular\";\n  padding-top: 3px;\n}\n.container.opened[data-v-e8d914be] {\n  height: 50px;\n}\n.block[data-v-e8d914be] {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.gap[data-v-e8d914be] {\n  flex-basis: 45px;\n}\n.gplay[data-v-e8d914be] {\n  flex: 1;\n}\n.gplay__icon[data-v-e8d914be] {\n  width: 143px;\n  height: 33px;\n}\n.close[data-v-e8d914be] {\n  flex-basis: 45px;\n}\n.close__icon[data-v-e8d914be] {\n  width: 15px;\n  height: 15px;\n}\nbody.light .gplay__icon[data-v-e8d914be] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\nbody.light .close__icon[data-v-e8d914be] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\nbody.light .block[data-v-e8d914be] {\n  background: #ECECED;\n}\nbody.twilight .gplay__icon[data-v-e8d914be] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\nbody.twilight .close__icon[data-v-e8d914be] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\nbody.twilight .block[data-v-e8d914be] {\n  background: #5A67A7;\n}\nbody.dark .gplay__icon[data-v-e8d914be] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\nbody.dark .close__icon[data-v-e8d914be] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\nbody.dark .block[data-v-e8d914be] {\n  background: #222939;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
