import router from '@/router';
import { connApp } from '@/helpers/signalR';
import DotwalletConnector from '@/wallets/Dotwallet';
import { PositionTypes } from '@/modules/positions/helpers';
import { updateAndSetUserSettings } from '@/modules/settings/api';

let firstInitAuth = false;

export default async function onConnectPlugin(store) {
  const hasAccessToken = () => {
    if (!store) {
      return false;
    }

    const activeConnect = store.getters['connectors/activeConnect'];

    return Boolean(activeConnect.accessToken);
  };

  const refreshAccessTokenIfNeeded = async () => {
    if (!hasAccessToken()) {
      return;
    }

    const activeConnect = store.getters['connectors/activeConnect'];

    if (activeConnect.provider === 'DotWallet') {
      if (activeConnect.authorizeCode) {
        await activeConnect.authorizeCode(activeConnect.code);
        store.dispatch('connectors/setActiveConnect', activeConnect);
      } else {
        const dotwalletWallet = new DotwalletConnector(activeConnect);
        store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      }
    }
  };

  const customRedirect = async () => {
    const href = router.currentRoute.value?.href || '/';

    if (['index?code', 'authToken'].some((part) => href.includes(part))) {
      await router.push('/');
    } else {
      await router.push(href);
    }
  };

  const logout = () => {
    store.dispatch('connectors/setAuthLoader', false);
    store.dispatch('connectors/setUserName', '', { root: true });

    const activeConnect = store.getters['connectors/activeConnect'];

    if (activeConnect.provider === 'Fiorin' && activeConnect.accessToken) {
      // window.location.reload();
    } else {
      store.dispatch('burger/fiorinMode', false, { root: true });
      store.dispatch('connectors/disconnect');
      store.dispatch('user/logout');
      store.dispatch('connectors/setActiveConnect', {});
      customRedirect();

      window.location.reload();
    }
  };

  const refreshUserData = async () => {
    setTimeout(() => {
      document.body.style.overflow = '';
    }, 1500);

    if (!hasAccessToken()) {
      return;
    }

    const activePositions = await connApp.invoke('FilterUserPositions', {
      state: 'Open,Proposed',
      take: 200,
    });

    store.dispatch('positions/setAllPositions', activePositions ?? []);

    store.commit(
      'session/SET_USER_TRADE_INFO',
      await connApp.invoke('GetTradingStats')
    );

    const historyPositions =
      (await connApp.invoke('FilterUserPositions', {
        state: 'History',
        take: 200,
      })) ?? [];

    const openPositions =
      store.getters['positions/getPositionsByType'][PositionTypes.open];
    const pendingPositions =
      store.getters['positions/getPositionsByType'][PositionTypes.pending];

    store.dispatch('positions/setAllPositions', [
      ...openPositions,
      ...pendingPositions,
      ...historyPositions,
    ]);

    store.commit(
      'session/SET_USER_TRADE_INFO_HISTORY',
      await connApp.invoke('GetClosedPositionsStats')
    );

    store.commit(
      'session/SET_USER_TRADE_INFO',
      await connApp.invoke('GetTradingStats')
    );
  };

  await refreshAccessTokenIfNeeded();
  connApp.on('connect', () => refreshUserData(store));

  if (hasAccessToken()) {
    if (!firstInitAuth) {
      await store.dispatch('connectors/setAuthLoader', true);
      firstInitAuth = true;
    }

    const activeConnect = store.getters['connectors/activeConnect'];

    if (!connApp.isSameUserConnected(activeConnect.serverInfo?.userId)) {
      const result = await connApp.restart();

      if (result === 'unauthorized') {
        logout();

        // TODO remove access token and restart connection

        return;
      }
    }
    await refreshUserData();
    let settings = await connApp.invoke('GetUser');

    if (activeConnect.provider !== 'Fiorin') {
      const el = document.querySelector('.static-splash');
      if (el) {
        el.style.display = 'none';
      }
    }

    window.document.dispatchEvent(
      new CustomEvent('closeLogin', { bubbles: true })
    );

    document.body.style.overflow = '';

    const authInfo = {
      provider: settings?.auth?.provider,
      providerId: settings?.auth?.providerId,
      userName: settings?.auth?.userName,
      userId: settings?.user?.id,
    };

    if (authInfo.userId) {
      await store.dispatch('connectors/setActiveConnectInfo', authInfo);
      await store.dispatch('user/setTruedexAuth', authInfo);
      await store.dispatch('connectors/setActiveConnect', {});

      activeConnect.serverInfo = authInfo;
      await store.dispatch('connectors/setActiveConnect', activeConnect);

      await store.dispatch('session/updateInfo');

      if (settings?.user) {
        await store.dispatch('settings/setUserSettings', settings.user);
      }

      if (activeConnect.provider === 'Fiorin') {
        store.dispatch('burger/fiorinMode', true, { root: true });
      } else {
        store.dispatch('burger/fiorinMode', false, { root: true });
      }

      if (activeConnect.provider === 'Fiorin') {
        let hasLogs = store.getters['settings/uiSettings'].balancesLog || [];

        if (hasLogs.length > 100) {
          hasLogs = [];
        }

        const logs = hasLogs.concat(activeConnect.balancesLog);
        logs.push(settings.bountyProgramAvailable);

        await updateAndSetUserSettings({
          uiSettings: {
            balancesLog: logs,
          },
        });
      }
    }
  }

  store.commit('connectors/UPDATE_LOADING_STATUS', false);
}
