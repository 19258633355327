<template>
  <div class="mobile layout-mobile">
    <div class="content">
      <router-view :isMobile="true" is-mobile />
    </div>
    <div v-show="isGraphVisible" style="z-index: 901">
      <MobileChart @close="hideChart" :visible="isGraphVisible" />
    </div>
    <div class="footer">
      <div
        v-if="
          !uiSettings.turnBountyPoints &&
          isPositionPage &&
          ((!disableBountyProgram && notStartProgram) ||
            startProgramAndNullBalance ||
            noStartProgramAndBountyBalance ||
            startProgramAndMainBalance)
        "
        :class="{
          isFiorinNullableBalance,
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
        }"
        class="bounty-points-wrap"
      >
        <BountyPoints />
      </div>
      <div
        v-if="
          !scrollingListFlag &&
          (wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram)
        "
        class="footer-line"
      />
      <MobileFooter />
      <BottomLogin v-if="!activeConnect.provider || isFiorinNullableBalance" />
      <div
        v-if="activeConnect.provider && (wd.navigator.standalone || isTelegram)"
        class="footer-panel"
      />
    </div>
    <SessionInfoModal
      v-if="modalStatus(modalsByName.tradingSession)"
      hide-toggle
      @close="handleTradingSessionClose"
    />
    <swaps-widget
      v-if="modalStatus(modalsByName.swaps)"
      @close="hideModal(modalsByName.swaps)"
      modal-class="widget-modal"
    />
    <FeaturesModal
      v-if="modalStatus('features')"
      @close="hideModal('features')"
    />
    <v-tour
      name="mobile-tour"
      :steps="steps"
      :options="tourOptions"
      :callbacks="tourCallbacks"
    />
    <HandCashNotify
      v-if="modalStatus(modalsByName.handCashNotify)"
      @close="hideModal(modalsByName.handCashNotify)"
    />
    <KYCVerification
      v-if="modalStatus(modalsByName.verification)"
      @close="hideModal(modalsByName.verification)"
    />
    <KYCStatus
      v-if="modalStatus(modalsByName.kycStatus)"
      @close="hideModal(modalsByName.kycStatus)"
    />
    <!-- <LostConnectionStatus
      v-if="modalStatus(modalsByName.lostConnectionStatus)"
      @close="hideModal(modalsByName.lostConnectionStatus)"
    /> -->
  </div>
</template>
<script>
import MobileFooter from '@/components/app/MobileFooter';
import BottomLogin from '@/components/app/BottomLogin';
import SessionInfoModal from '@/modules/session/components/SessionInfoModal';
import SwapsWidget from '@/components/modals/SwapsWidget';
import FeaturesModal from '@/modules/features/index.vue';
import HandCashNotify from '@/components/modals/HandCashNotify';
import KYCVerification from '@/components/modals/KYCVerification';
import KYCStatus from '@/components/modals/KYCStatus';
import MobileChart from '@/components/ui/mobile/MobileChart';
// import LostConnectionStatus from '@/components/modals/MainConnectionLost.vue';

import { computed, defineComponent, onMounted } from 'vue';
import { useModals } from '@/modules/modals/api';
import tourMixin from '@/mixins/tour.mixin';
import { mapGetters, useStore } from 'vuex';
import { setRouteFromActiveMarket } from '@/helpers/activeMarketRoute';
import useUserActions from '@/compositions/useUserActions';
import { useRoute, useRouter } from 'vue-router';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';
import BountyPoints from '@/components/BountyPoints';
import { useBountyPrograms } from '@/compositions/useBountyPrograms';

export default defineComponent({
  components: {
    BountyPoints,
    MobileFooter,
    BottomLogin,
    SessionInfoModal,
    SwapsWidget,
    FeaturesModal,
    HandCashNotify,
    KYCVerification,
    KYCStatus,
    MobileChart,
    // LostConnectionStatus,
  },
  mixins: [tourMixin],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { showModal, hideModal, modalStatus, modalsByName } = useModals();
    const { isFiorinNullableBalance } = useFiorinBalanceNull();
    const {
      notStartProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
    } = useBountyPrograms();

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const isPositionPage = computed(() => {
      return route?.name?.toLowerCase() === 'positions';
    });

    const disableBountyProgram = computed(
      () => store.getters['settings/settings']?.disableBountyProgram
    );

    const isGraphVisible = computed(() => {
      return store.getters['graph/isGraphVisible'];
    });

    const scrollingListFlag = computed(() => {
      return store.getters['localUiSettings/scrollingListFlag'];
    });

    const hideChart = () => {
      store.dispatch('graph/toggleGraphVisibility', false);
    };

    useUserActions();

    onMounted(() => {
      if (route.path.includes('/market/')) {
        router.push('/markets');
      }
      setTimeout(() => {
        document.body.style.overflow = '';
      }, 1000);
    });

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    return {
      uiSettings,
      activeConnect,
      showModal,
      hideModal,
      modalStatus,
      modalsByName,
      isGraphVisible,
      hideChart,
      wd: window,
      isRunningStandaloneAndroid,
      scrollingListFlag,
      isTelegram,
      isFiorinNullableBalance,
      notStartProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
      route,
      disableBountyProgram,
      isPositionPage,
    };
  },
  data() {
    return {
      nextStepTimeout: null,
      steps: [
        {
          target: '#v-step-0',
          content: `Here are the markets you can trade.`,
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        },
        {
          target: '#v-step-1',
          content: 'Please select a market category.',
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        },
        {
          vm: this,
          target: '#v-step-mobile-2',
          content: 'Please click the market you wish to trade.',
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.$router.push(`/markets`);
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
          },
        },
        {
          vm: this,
          target: '#tv_chart_container',
          content:
            'Here you can conduct technical analysis on the selected market.',
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            setRouteFromActiveMarket();
            await new Promise((resolve) => {
              setTimeout(resolve, 700);
            });
          },
        },
        {
          vm: this,
          target: '#v-step-mobile-4',
          content: `Before opening the trade please study the 'Market data' panel, each asset has different terms.`,
          params: {
            placement: 'bottom-start',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.hideModal(this.vm.modalsByName.marketData);
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
          },
        },
        {
          vm: this,
          target: '#v-step-4',
          content: `Please view these numbers and their meaning.`,
          params: {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.showModal(this.vm.modalsByName.marketData);
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
          },
        },
        {
          vm: this,
          target: '#v-step-5',
          content: `This is where you create positions. Go long (buy/green) or short (sell order/red).`,
          params: {
            placement: 'top-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.hideModal(this.vm.modalsByName.marketData);
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
          },
        },
        {
          vm: this,
          target: '#v-step-mobile-3',
          content: `The amount is equal to margin (collateral) multiplied by leverage (max by default). Click 'More' on the right side to define entry price, change leverage or set 'Target/Stop' in advance.`,
          params: {
            placement: 'top-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            setRouteFromActiveMarket();
            document.querySelector('.positon-order-btns__btn.buy')?.click();
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
          },
        },
        {
          vm: this,
          target: '#v-step-mobile-6',
          content: `Click on the <svg style="position: relative; top: 4px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 44.127 41.25" aria-labelledby="orders" role="presentation"><title id="orders" lang="en">orders icon</title><g fill="currentColor" stroke="none"><path pid="0" d="M0 10.555l1.758 1.637L8.313 5.14v36.11h3V5.136l6.558 7.057 1.758-1.637L9.815 0 0 10.555zm42.369 18.503l-6.556 7.053V0h-3v36.114l-6.558-7.057-1.758 1.637 9.813 10.555 9.815-10.555-1.756-1.636z"></path></g></svg> positions tab.`,
          params: {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.$router.push('/markets');
            await new Promise((resolve) => {
              setTimeout(resolve, 500);
            });
          },
        },
        {
          vm: this,
          target: '.positions-tabs',
          content: `Here you can see all of your open, pending or historic positions.`,
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.$router.push('/positions');
            await new Promise((resolve) => {
              setTimeout(resolve, 700);
            });
          },
        },
        {
          vm: this,
          target: '.positions-items',
          content: `You can click on any of your positions to see details, manage 'Target/Stop' prices or split the position into two or more new positions to be managed separately.`,
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.$router.push('/positions');
            await new Promise((resolve) => {
              setTimeout(resolve, 700);
            });
          },
        },
        {
          target: '#v-step-mobile-11',
          content: `Click on the <svg style="position: relative; top: 4px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46.617 48.978" aria-labelledby="account" role="presentation"><title id="account" lang="en">account icon</title><g fill="currentColor" stroke="none"><path pid="0" d="M46.573 47.391c-.333-11.313-7.822-13.646-11.02-14.642l-.42-.132c-3.185-1.012-8.234-1.465-11.825-1.465-3.59 0-8.639.453-11.826 1.465l-.419.131C7.866 33.744.376 36.077.043 47.39l-.045 1.499 2.999.088.044-1.5c.271-9.173 5.682-10.858 8.914-11.865l.436-.137c2.865-.91 7.667-1.324 10.917-1.324 3.251 0 8.054.415 10.916 1.324l.437.137c3.232 1.007 8.644 2.692 8.914 11.865l.044 1.5 2.999-.088-.045-1.498zM23.309 26.729c6.341 0 10.931-9.12 10.931-15.268C34.239 5.141 29.336 0 23.309 0 17.28 0 12.376 5.141 12.376 11.46c0 6.148 4.591 15.269 10.933 15.269zm0-23.729c4.373 0 7.931 3.795 7.931 8.46 0 5.13-3.996 12.268-7.931 12.268-3.936 0-7.933-7.138-7.933-12.268 0-4.665 3.559-8.46 7.933-8.46z"></path></g></svg> account tab.`,
          params: {
            placement: 'top-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        },
        {
          vm: this,
          target: '.account-info.box',
          content: `Here you can see your wallet balance, pending and current positions as well as current risk.`,
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.$router.push('/account');
            await new Promise((resolve) => {
              setTimeout(resolve, 700);
            });
          },
        },
        {
          target: '.account-info.box',
          content: `You can also see your Grand Total, your unrealized profit/loss and unsettled profits.`,
          params: {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        },
        {
          target: '.burger-menu__icon',
          content: `Click on the <svg style="position: relative; top: 4px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48.648 48" aria-labelledby="burger" role="presentation" data-v-203d0c90=""><title id="burger" lang="en">burger icon</title><g fill="currentColor" stroke="currentColor"><path pid="0" d="M33.008 0v3h15.641V0H33.008zM11.293 25.5h37.355v-3H11.293v3zM0 48h48.648v-3H0v3z"></path></g></svg> menu button.`,
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        },
        {
          vm: this,
          target: '.burger-menu__item',
          content: `Click on the 'Trading session' item.`,
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.hideModal(this.vm.modalsByName.tradingSession);
            const burgerIcon = document.querySelector(
              '.burger-menu__icon.closed'
            );
            if (
              burgerIcon &&
              burgerIcon.querySelector('svg[aria-labelledby="burger"]')
            ) {
              burgerIcon.click();
            }
            await new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 500);
            });
          },
        },
        {
          vm: this,
          target: '#modal-container .modal__body .card.box',
          content: `This panel shows current session liquidity, i.e. how much communal profit available for all traders to win. It is calculated based on the current trading session profit/loss balance and coverage from the liquidity pool. Profits and losses are settled against each other every 8 hours.`,
          params: {
            placement: 'top-start',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          async before() {
            this.vm.showModal(this.vm.modalsByName.tradingSession);
            await new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });
          },
        },
      ],
      tourOptions: {
        highlight: true,
        useKeyboardNavigation: false,
        labels: {
          buttonPrevious: '',
          buttonNext: '',
          buttonStop: 'Finish',
        },
      },
      tourCallbacks: {
        onPreviousStep: this.onTourPrevStep,
        onNextStep: this.onTourNextStep,
        onFinish: this.onTourFinish,
      },
    };
  },
  computed: {
    ...mapGetters({
      activeMarketId: 'markets/activeMarketId',
    }),
  },
  methods: {
    onTourFinish() {
      this.hideModal(this.modalsByName.tradingSession);
    },
    onTourNextStep(currentStep) {
      const tourWrapper = document.querySelector('.v-tour');

      if (tourWrapper) {
        tourWrapper.setAttribute('data-step', currentStep + 1);

        switch (currentStep) {
          case 2:
            setRouteFromActiveMarket();
            break;
          case 8:
            this.$router.push('/positions');
            break;
        }
      }
    },
    onTourPrevStep(currentStep) {
      const tourWrapper = document.querySelector('.v-tour');

      if (tourWrapper) {
        tourWrapper.setAttribute('data-step', currentStep - 1);

        switch (currentStep) {
          case 3:
            this.$router.push('/markets');
            break;
          case 8:
            setRouteFromActiveMarket();
        }
      }
    },
    handleTradingSessionClose() {
      this.$tour_goToNextStep(this.$tour_mobile, () => {});
      this.hideModal(this.modalsByName.tradingSession);
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  max-height: 100vh;
  // overflow-y: auto;
  .bounty-points-wrap {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 70px;

    &.standalone {
      bottom: 85px;
    }

    &.isFiorinNullableBalance {
      bottom: 130px;

      &.standalone {
        bottom: 145px;
      }
    }
  }

  &.scrolling {
    height: auto;
    max-height: initial;
    // position: fixed;
  }

  .content {
    min-height: 100%;
    height: fit-content;
    overflow-y: visible;

    > * {
      height: fit-content;
      min-height: 100%;
      width: 100%;
      position: absolute;
    }

    > .positions-list.positions-list--mobile {
      min-height: 100%;
    }
  }

  .footer-panel {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 32px;
    left: 0;
    bottom: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 900;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom-color: transparent;

    .footer-line {
      height: 2px;
      width: 100%;
      background: #485490;
    }
  }

  .mt-10 {
    margin-top: $px10;
  }

  .mb-10 {
    margin-bottom: $px10;
  }

  .ml-10 {
    margin-left: $px10;
  }
}

body.light {
  .mobile {
    .footer {
      .footer-line {
        background: #ebebeb;
      }
    }
  }
}

body.dark {
  .mobile {
    .footer-panel {
      background: #121722;
    }
    .footer {
      .footer-line {
        background: rgb(43, 46, 63);
      }
    }
  }
}
</style>
