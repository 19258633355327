import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import EventEmitter from 'events';
import config from '@/config';
import store from '../store';
import { v4 as uuidv4 } from 'uuid';

const RECONNECT_TIMEOUT = 1000;

// const dispatchCloseAndReload = () => {
//   window.document.dispatchEvent(
//     new CustomEvent('closeLogin', { bubbles: true })
//   );
//   window.location.reload();
// };

class SignalRConnection extends EventEmitter {
  constructor(host, withAuth) {
    super();

    this.host = host;
    this.connected = false;

    this._connection = new HubConnectionBuilder()
      .withUrl(this._buildConnectionString(), {
        accessTokenFactory: withAuth ? this._accessTokenFactory : undefined,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * RECONNECT_TIMEOUT,
      })
      .configureLogging(LogLevel.Critical)
      .build();

    this._connection.onclose(this._onDisconnect);
    this._connection.onreconnected(this._onReconnecting);
    this._connection.onreconnecting((error) => {
      if (error) {
        console.log({ type: 'Reconnected', error });
      } else {
        this.emit('connect');
      }
    });

    document.addEventListener('visibilitychange', this._ensureInValidState);
    window.addEventListener('focus', this._ensureInValidState);
  }

  _onDisconnect = () => {
    this.connected = false;

    console.log('Socket connection has closed');
    console.log(`Will try again in ${RECONNECT_TIMEOUT}ms`);
  };

  _onReconnecting = () => {
    this._onDisconnect();
  };

  _ensureInValidState = async () => {
    if (document.visibilityState !== 'visible') {
      return;
    }

    if (
      this._connection.state === HubConnectionState.Disconnected ||
      this._connection.state === HubConnectionState.Disconnecting
    ) {
      this.connected = false;

      await this.start();
    }
  };

  _buildConnectionString = () => {
    let uuid = localStorage.getItem('dxsUUID');

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('dxsUUID', uuid);
    }

    return this.host + `?browserId=${uuid}`;
  };

  _getAccessData = () => {
    if (!store) {
      console.log({
        type: '_accessTokenFactory',
        message: 'Init signalR connection before store initialized',
      });

      return {
        provider: null,
        accessToken: null,
        userId: null,
      };
    }

    const activeConnect = store.getters['connectors/activeConnect'];

    const provider = activeConnect.provider;
    const accessToken = activeConnect.accessToken;

    return {
      provider,
      accessToken,
      userId: activeConnect.serverInfo?.userId,
    };
  };

  _hasAccessToken = () => Boolean(this._getAccessData().accessToken);

  _buildBearerToken = (provider, accessToken) => `${provider}:${accessToken}`;

  _accessTokenFactory = () => {
    const { provider, accessToken, userId } = this._getAccessData();

    if (!accessToken) {
      return;
    }

    this._userId = userId;

    return this._buildBearerToken(provider, accessToken);
  };

  // some wierd cases to verify
  // setTimeout(() => {
  //   if (!provider || !store.getters['connectors/activeConnect']?.provider) {
  //     return;
  //   }

  //   if (activeConnect.provider === 'Fiorin') {
  //     if (provider !== store.getters['connectors/activeConnect']?.provider) {
  //       dispatchCloseAndReload();
  //     }

  //     return;
  //   }

  //   if (
  //     accessToken !== store.getters['connectors/activeConnect']?.accessToken
  //   ) {
  //     dispatchCloseAndReload();
  //   }
  // }, RECONNECT_TIMEOUT);

  start = async () => {
    try {
      await this._connection.start();
      this.emit('connect');

      this.connected = true;
    } catch (err) {
      if (err.statusCode === 401) {
        // means accessToken is invalid, need to delete accessToken from store and relogin
        this.unauthorized = true;
        this.emit('unauthorized');

        return 'unauthorized';
      }
    }
  };

  isSameUserConnected = (userId) => userId === this._userId;

  stop = async () => {
    await this._connection.stop();
  };

  restart = async () => {
    await this.stop();
    return await this.start();
  };

  subscribe = (methodName, callback) => {
    this._connection.off(methodName, callback);
    this._connection.on(methodName, callback);
  };

  invoke = (methodName, ...args) => {
    // console.log({ type: 'invoke', methodName, args });

    if (this.unauthorized) {
      throw new Error('Unable to invoke method because unauthorized');
    }

    if (this._connection.state === HubConnectionState.Connected) {
      return this._connection.invoke(methodName, ...args);
    }

    console.error(
      `Socket connection is in invalid state: "${this._connection.state}"`
    );

    return new Promise((resolve) =>
      this.once('connect', async () => {
        const result = await this._connection.invoke(methodName, ...args);

        console.warn('REssdfasdfasf', methodName, result);

        resolve(result);
      })
    );
  };
}

export function getErrorDetails(err) {
  try {
    // parse SignalR hardcoded string
    var msgJson = (
      err.message.match(/HubException: (.*)/) || [err.message]
    ).pop();
    return JSON.parse(msgJson);
  } catch (e) {
    return { id: null, message: err.message };
  }
}

export const connMarkets = new SignalRConnection(config.wsUrl + 'markets');
export const connApp = new SignalRConnection(config.wsUrl + 'app', true);
