import { connApp, getErrorDetails } from '@/helpers/signalR';
import store from '@/store';

export async function getUserSettings() {
  try {
    const settings = await connApp.invoke('GetUser');

    if (settings?.user) {
      store.dispatch('settings/setUserSettings', settings.user);
    }
  } catch (e) {
    console.error(e);
  }
}

export async function updateUserSettings({ uiSettings, email }) {
  if (!store.getters['user/userId']) {
    return;
  }
  const params = {};
  if (uiSettings) {
    params.UISettings = uiSettings;
  }
  if (email) {
    params.Email = email;
  }
  let error;
  await connApp
    .invoke('UpdateUserInfo', params)
    .catch((err) => (error = getErrorDetails(err)));
  if (error) {
    return error;
  }
}

export async function updateAndSetUserSettings({ uiSettings, email }) {
  const oldSettings = store.getters['settings/uiSettings'];
  const settingsToUpdate = Object.assign(oldSettings, uiSettings);

  if (Object.prototype.hasOwnProperty.call(uiSettings, 'bountyPaused')) {
    // store.dispatch('bounty/setBountyPaused', uiSettings.bountyPaused);
  }

  try {
    await updateUserSettings({
      uiSettings: JSON.stringify(settingsToUpdate),
      email,
    });
    await getUserSettings();
  } catch (error) {
    console.error(
      `Unable to update uiSettings ${uiSettings}, error: ${error.stack}`
    );
  }
}
